import React from "react"
import { Link, graphql } from "gatsby"
import parse from "html-react-parser"

import Layout from "../components/layout"
import Seo from "../components/seo"

const CategoryTemplate = ({ data: { categoryContent, category } }) => {
    if (!categoryContent.posts.length) {
        return (
            <Layout>
                <Seo title={`${category.edges[0].node.name} - All posts`} />
                <p>
                    No blog posts found. Add posts to your WordPress site and they'll
                    appear here!
                </p>
            </Layout>
        )
    }

    return (
        <Layout>
            <Seo title={`${category.edges[0].node.name} - All posts"`} />
            <h1 className="headline">{category.edges[0].node.name}</h1>
            <div className='spacer'></div>
            <ol className='article-list'>
                {categoryContent.posts.map(post => {
                    const title = post.title

                    return (
                        <li key={post.uri}>
                            <article
                                className="entry"
                                itemScope
                                itemType="http://schema.org/Article"
                            >
                                <header>
                                    <h2 itemProp='headline' className='entry-title'>
                                        <Link to={post.uri} itemProp="url">
                                            {parse(title)}
                                        </Link>
                                    </h2>
                                    <div className="entry-date">{post.date}</div>
                                </header>
                                <section itemProp="articleBody" className='entry-content'>{parse(post.content)}</section>
                            </article>
                        </li>
                    )
                })}
            </ol>
        </Layout>
    )
}

export default CategoryTemplate

export const pageQuery = graphql`
query CategoryBySlug(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
    $name: String!
    ) {
        category: allWpCategory(filter: {name: {eq: $name}}) {
            edges {
                node {
                  name
                }
            }
        }
        categoryContent: allWpPost(filter: {categories: {nodes: {elemMatch: {slug: {eq: $id}}}}}) {
            posts: nodes {
                title
                uri
                content
                date(formatString: "MMMM DD, YYYY")
                categories {
                  nodes {
                    name
                    slug
                  }
                }
            }
        }
    }
`